<template>
  <div
    :class="{
      'e-message--read': true,
      'e-message--answered': true,
      'e-message--open': isOpen
    }"
    class="e-message e-message--ingoing"
    @click="toggleDialog"
  >
    <div v-if="dialogLoading.indexOf(data.id) > -1" class="e-message__loading">
      <loading />
    </div>

    <div class="e-message__meta">
      <div
        class="e-message__author"
        v-text="data.outgoing ? $t('system.me') : data.author"
      />
      <span class="e-message__meta__right">
        <time :datetime="data.timestamp" class="e-message__date">
          {{ data.timestamp | simpledate }}
        </time>
      </span>
    </div>

    <div class="e-message__body">
      <h3 class="e-message__subject">Chat mit {{ data.counselor }}</h3>

      <p class="e-message__content" v-html="trimContent(data.body)" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from 'atoms/Loading/Loading'

export default {
  name: 'ChatMessageListItem',
  components: {
    Loading
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['dialogLoading']),

    isOpen() {
      return this.$route.params.dialogId === this.data.id
    }
  },
  methods: {
    trimContent(content) {
      let simpleContent = content
        .replace(/<br\s*[\/]?>/gi, ' ') // eslint-disable-line
        .replace('</div><div>', ' ')
        .replace('<div>', ' ')
      const div = document.createElement('div')
      div.innerHTML = simpleContent
      simpleContent = div.textContent || div.innerText || ''
      return simpleContent.length > 100
        ? `${simpleContent.substring(0, 100)}...`
        : simpleContent
    },

    toggleDialog() {
      const dialogId = this.data.rootId || this.data.id
      const messageId = this.data.rootId ? this.data.id : false

      const params = {}
      params.dialogId = dialogId

      if (messageId) params.messageId = messageId

      this.$router.push({
        name: this.$route.meta.detailsRoute,
        params: params
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/_chatmessagepreview';
</style>
